(function ($) {
  $.fn.shippingTimer = function (options, countdownTimer) {
    const self = this;
    self.countdownTimer = countdownTimer;
    return this.each(function (index, element) {
      let shippingTimer = new ShippingTimer(options, element, self.countdownTimer);
      $.data(this, 'shippingTimer', shippingTimer);
      return this;
    });
  };
  $.fn.shippingTimer.options = {
    hourSelector: '.hour',
    minuteSelector: '.minute',
    secondSelector: '.second'
  };
  function ShippingTimer(options, elem, countdownTimer) {
    this.elem = elem;
    this.$elem = $(elem);
    this.options = $.extend({}, $.fn.shippingTimer.options, options);
    this.options.hideWhenZero = this.$elem.data('shippingTimerHideOnZero') || false;
    this.options.initialTimeRemaining = this.$elem.data('shippingTimerRemaining') || 0;
    this.countdownTimer = countdownTimer;
    if (!this.countdownTimer) {
      this.countdownTimer = new DK.CountDownTimer(this.options.initialTimeRemaining);
    }
    this.countdownTimer.onTick(this.updateTimeRemaining.bind(this)).onTick(this.hideTimerWhenExpired.bind(this));
    this.countdownTimer.start();
  }
  ShippingTimer.prototype.updateTimeRemaining = function (countdownObj) {
    this.decrementField(this.options.secondSelector, countdownObj.seconds);
    this.decrementField(this.options.minuteSelector, countdownObj.minutes);
    this.decrementField(this.options.hourSelector, countdownObj.hours);
  };
  ShippingTimer.prototype.decrementField = function (fieldClass, field) {
    let $value = this.$elem.find(fieldClass);
    let tens = $value[0];
    let ones = $value[1];
    if (field < 10) {
      tens.textContent = '0';
      ones.textContent = field.toString()[0];
    } else {
      tens.textContent = field.toString()[0];
      ones.textContent = field.toString()[1];
    }
  };
  ShippingTimer.prototype.hideTimerWhenExpired = function (countdownObj) {
    let settings = this.options;
    if (!settings.hideWhenZero || !this.countdownTimer.expired()) {
      return;
    }
    if (settings.hideWhenZero === true) {
      this.hideElement(this.$elem);
    } else if (typeof settings.hideWhenZero === 'string') {
      this.hideElement($(settings.hideWhenZero));
    }
  };
  ShippingTimer.prototype.hideElement = function ($element) {
    $element.css('display', 'none');
  };
})(jQuery);
$(function () {
  $('[data-shipping-timer]').shippingTimer();
});
(function (window, undefined) {
  window.DK = window.DK || {};
  window.DK.CountDownTimer = CountDownTimer;

  // A good chunk of logic is taken from this stackoverflow answer
  // https://stackoverflow.com/a/20618517
  function CountDownTimer(duration) {
    this.duration = duration;
    this.tickFunctions = [];
    this.running = false;
  }

  /**
   * Starts the timer, calling any tickFunction callbacks each time the
   * second changes.
   */
  CountDownTimer.prototype.start = function () {
    if (this.running) {
      return;
    }
    this.running = true;
    let start = Date.now(),
      self = this,
      diffInSeconds,
      timerValues;
    (function timer() {
      // calculate the difference (in seconds) between the current
      // time and the start time
      diffInSeconds = self.duration - ((Date.now() - start) / 1000 | 0);
      if (diffInSeconds > 0) {
        setTimeout(timer, 1000);
      } else {
        diffInSeconds = 0;
        self.running = false;
      }
      timerValues = self.parse(diffInSeconds);
      self.tickFunctions.forEach(function (fn) {
        fn.call(this, timerValues);
      }, self);
    })();
  };

  /**
   * Checks to see if the timer has reached zero or if the countdown is still
   * in progress.
   * @returns {boolean} true if the timer has reached zero, false if the
   *          timer is still counting down.
   */
  CountDownTimer.prototype.expired = function () {
    return !this.running;
  };

  /**
   * Adds a callback handler to the countdown timer. The callback will be
   * passed an object with the hours, minutes, and seconds remaining in
   * the countdown.
   * @param fn Function to call on tick
   * @returns {CountDownTimer} This timer, suitable for chaining onTick calls
   */
  CountDownTimer.prototype.onTick = function (fn) {
    if (typeof fn === 'function') {
      this.tickFunctions.push(fn);
    }
    return this;
  };

  /**
   * Turns the number of seconds into a countdown object with the number of
   * hours, minutes in the hour, and seconds in the minute remaining.
   * @param totalSeconds The total number of seconds left in the countdown
   * @returns {{hours: number, seconds: number, minutes: number}}
   */
  CountDownTimer.prototype.parse = function (totalSeconds) {
    // 60 seconds in a minute
    let secondsInMinute = 60;

    // 60 seconds * 60 minutes = 3600 seconds in an hour
    let secondsInHour = secondsInMinute * 60;

    // 60 seconds * 60 minutes * 24 hours = 86400 seconds in a day
    let secondsInDay = secondsInHour * 24;

    // Calculate remaining time sections and then round them to remove fractions
    return {
      hours: totalSeconds % secondsInDay / secondsInHour | 0,
      minutes: totalSeconds % secondsInHour / secondsInMinute | 0,
      seconds: totalSeconds % secondsInMinute | 0
    };
  };
})(window);