var DK = DK || {};
DK.intercept = DK.intercept || {
  intercept_content: function () {
    let links = new Array(arguments.length);
    let html = `<div class="intercept-modal"><ul class="list-group">{{{}}}
			</ul></div>`;
    for (let i = 0; i < arguments.length; i++) {
      if (arguments[i] != '') {
        links[i] = `<li class="list-group-item">${arguments[i]}</li>`;
      }
    }
    return html.replace(/{{{}}}/, links.join('\n'));
  },
  intercept_brands: function (evt) {
    let mmyUri = $('#active-ride-bar-spec .mmy-spec-bar').attr('data-active-ride');
    let mmy = $('#active-ride-bar-spec #title').text();
    let url = window.location.href;
    let rideOnLink = `<a href="${url}/${mmyUri}" data-dismiss="modal">
			View Brands for your ${mmy}</a>`;
    url.searchParams.set('ride_off', 'true');
    let rideOffLink = `<a href="${url}>View All Brands</a> - Turn Off Ride`;
    let content = DK.intercept.intercept_content(rideOnLink, rideOffLink);
    DK.intercept.modal(content);
    DK.intercept.check_cookie('nav');
    return false;
  },
  intercept_navigation: function () {
    let url = new URL(window.location.origin + $(this).attr('href'));

    // if(url.includes('black-friday')) {
    // 	// temporary hack to stop interceptor from firing on black friday pages
    // 	return false;
    // }
    let category = this.innerHTML;
    let dept = $(this).parents('[data-name]').attr('data-name');
    let mmyUri = $('.mmy-spec-bar').attr('data-active-ride');
    let mmy = $('#active-ride-bar-spec #title').text() || $('#ride-title').text();
    let rideOnLink = `<a href="${url.pathname}/${mmyUri}${url.search}">View ${category} for your ${mmy}</a>`;
    url.searchParams.set('ride_off', 'true');
    let rideOffLink = `<a href="${url.pathname}${url.search}">View All ${category}`;
    if (dept) {
      // Department does not always exist or exists in the link text.
      rideOffLink += ` in ${dept}`;
    }
    rideOffLink += `</a> - Turn Off Ride`;
    DK.intercept.modal(DK.intercept.intercept_content(rideOnLink, rideOffLink));
    DK.intercept.check_cookie('nav');
    return false;
  },
  intercept_search: function (evt) {
    var url = new URL(window.location.origin + window.location.pathname);
    var mmyUri = $('.mmy-spec-bar').attr('data-active-ride');
    var mmy = $("#active-ride-bar-spec #title").text() || $("#ride-title").text();
    var searchString = $('.hsearch__input:visible').val();
    var search = '';
    if ($('input[name="outletSearch"]').val() === 'true') {
      url.searchParams.set('outlet', true);
    }
    var interceptArgs = [];
    var searchParam = '';
    var queryString = '';
    if (searchString !== '' && searchString !== 'Part Number, Brand, Keyword, etc.') {
      searchParam = DK.intercept.encode(searchString, 'query');
      queryString = '?' + searchParam;
    } else {
      queryString = '?items_per_page=40&sort=pricedesc';
    }
    if (searchParam !== '') {
      search = 'search/';
    }
    interceptArgs.push(`<a href="/${search}${mmyUri}${queryString}">
				"${searchString}" for your ${mmy}</a>`);
    interceptArgs.push(`<a href="/search${queryString}&ride_off=true">
			Search "${searchString}" in All Results</a> - Turn Off Ride`);
    DK.intercept.modal(DK.intercept.intercept_content.apply(null, interceptArgs));
    DK.intercept.check_cookie('search');
    return false;
  },
  modal: function (content) {
    let $wrapper = $('#ride-intercept-modal');
    if ($wrapper.length === 0) {
      if (window.console) {
        console.log('No ride intercept modal is on the page!');
      }
      return;
    }
    $wrapper.find('.modal-body-content').html(content);
    let modal = bootstrap.Modal.getInstance($wrapper[0]);
    if (modal == null) {
      modal = new bootstrap.Modal($wrapper[0]);
    }
    modal.show();
  },
  set_cookie: function (cname, cvalue) {
    document.cookie = `${cname}=${cvalue}; Path=/; Secure`;
  },
  get_cookie: function (cname) {
    let cookies = document.cookie.split(';');
    let val = 0;
    for (i in cookies) {
      let cookie = cookies[i];
      if (cookies[i].indexOf(cname + '=') > -1) {
        val = parseInt(cookies[i].split('=')[1]);
        val = isNaN(val) ? 0 : val;
      }
    }
    return val;
  },
  /**
   * Create/update cookie for tracking the number of times user's see
   * the search/nav intercept modal.
   */
  check_cookie: function (cname) {
    let sectionCount = DK.intercept.get_cookie(cname) + 1;
    let totalCount = DK.intercept.get_cookie('total_intercept') + 1;
    let sectionValue = `${cname}_${sectionCount}`;
    let totalValue = `total_intercept_${totalCount}`;
    DK.intercept.set_cookie(cname, sectionCount);
    DK.intercept.set_cookie('total_intercept', totalCount);
    //set customer letiable
    if (typeof _gaq !== 'undefined') {
      _gaq.push(['_setAccount', 'UA-557548-2']);
      _gaq.push(['_setCustomVar', 1, 'Intercept_Count', sectionValue, 2]);
      _gaq.push(['_setCustomVar', 2, 'Intercept_Count', totalValue, 2]);
      _gaq.push(['_trackEvent', 'Ride', 'Interrupt', cname, sectionCount]);
    }
  },
  encode: function (str, param) {
    let filtered = encodeURIComponent(str).replace(/%20/g, '+').replace(/%2F/g, '%252F').replace(/%5C/g, '');
    if (filtered.length > 0 && param) {
      return param + "=" + filtered;
    }
    return filtered;
  }
};
$(function () {
  if ($('.ride-toggle #ride-bar-state').is(':checked') || $('#active-ride-bar-nav').length > 0) {
    $('[data-level="2"]:not(.tb-soft__goods) ul a').on('click', DK.intercept.intercept_navigation);
    $('form.hsearch').on('submit', DK.intercept.intercept_search);
  }
});