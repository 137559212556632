(function ($, window, document, undefined) {
  let pluginName = 'tabitha',
    defaults = {
      // overlap: there will be a gap between open levels
      // cover: the open levels will be on top of any previous open level
      type: 'cover',
      // overlap || cover
      levelSpacing: 0,
      // space between each overlaped level
      backClass: 'tb-cats__back',
      // classname for the element (if any) that when clicked closes the current level
      trigger: '#trigger',
      // id || class of element to trigger the menu
      pusher: '#tb-pusher',
      // the container wrapper that will be moved when the menu is triggered
      linkSelector: 'a',
      // Only apply click handling to the given elements
      blog: '[data-tabitha-blog]',
      outlet: '[data-tabitha-outlet]'
    };

  // The actual plugin constructor
  function Plugin(element, options) {
    this.element = element;
    this.settings = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = pluginName;
    $('body').prepend('<div id="overlay">');
    this.getMobileCategories($(this.element)).addClass('l1');
    this.init();
  }

  // Avoid Plugin.prototype conflicts
  $.extend(Plugin.prototype, {
    init: function () {
      let self = this;
      const activeCategory = $('.tb-ps-tabs__link--active', self.element).data('navCategory'),
        activeMenu = $(`.tb-navbar__nav[data-department-nav="${activeCategory}"]`, self.element);

      // if menu is open or not
      self.open = false;

      // level depth
      self.level = 0;

      // the moving wrapper
      self.wrapper = $(self.settings.pusher)[0];

      // the tb__level elements
      self.levels = $('.tb__level', self.element);
      if (activeMenu.length > 0) {
        // this is pretty ugly, but I was not able to find another way
        // to get the elements in the required order, setting this.levels
        // directly using both selectors always returns the elements in
        // the order that match .tb__level regardless of how I ordered
        // the selectors
        self.levels[0] = activeMenu[0];
      }

      // the menu items
      self.menuItems = Array.prototype.slice.call(self.element.querySelectorAll(this.settings.linkSelector));
      self.levelBack = $(`.${self.settings.backClass}`, self.element);

      // event type
      self.eventtype = 'click';

      // add the class tb-overlap or tb-cover to the main element depending on options.type
      $(self.element).addClass(`tb-${self.settings.type}`);

      // initialize / bind the necessary events
      self._initEvents();
    },
    _initEvents: function () {
      let self = this;
      let levelTwo = $('.tb__level[data-level=2]'),
        flyouts = $('[data-tb-flyout]');
      self._initHoverIntent();

      // the menu should close if clicking somewhere on the body
      let bodyClickFn = function (el) {
        self._resetMenu();
        el.removeEventListener(self.eventtype, bodyClickFn);
      };

      // open (or close) the menu
      $(self.settings.trigger).on(self.eventtype, function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if (self.open) {
          self._resetMenu();
        } else {
          self._openMenu();
          // the menu should close if clicking somewhere on the body (excluding clicks on the menu)
          $(document).on('touchend click', function (ev) {
            if ($(ev.target).hasClass('tb--pushed')) {
              //Prevents accidentally hitting random link element on page body
              ev.preventDefault();
              bodyClickFn(this);
            }
          });
          $('html').addClass('active');
          $('#overlay, .footer-outer').addClass('tb--pushed');
          $(self.levels[0]).addClass('tb__level--open');
          $('html').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function (e) {
            self.getMobileCategories($(self.element)).removeClass('l1');
            levelTwo.show();
          });
        }
      });

      //Close the flyout when the user clicks outside of it.
      $(document).on('mouseup', function (e) {
        if ($('html').hasClass('active')) {
          // Ignore when the mobile nav is open. The desktop flyout cannot
          // be open at the same time the mobile nav is open.
          return;
        }
        let menu = $('#mobile-menu');
        if (!menu.is(e.target) && menu.has(e.target).length == 0) {
          self._closeMenu(flyouts);
        }
      });
      self._initAddtlEvents();

      // opening a sub level menu
      self._initMenuLinks();
    },
    _initAddtlEvents: function () {
      //Created to account for ajax or... not ajax.
      //If all events are reinitialized, things break.
      const self = this;
      let flyouts = $('[data-tb-flyout]'),
        levelTwo = $('.tb__level[data-level=2]'),
        tabs = $('[data-tb-tab]');
      self._initHoverIntent();
      let tabFn = function (el) {
        let newTab = el.currentTarget;
        if ($('html').hasClass('active')) {
          el.preventDefault();
          self.replaceCategories(newTab);
        } else if ($(window).width() > 992 && $(window).width() < 1300 && self._isHomePage()) {
          self._changeTabs($(newTab));
        }
      };

      // The menu should close when the window is resized
      // above the mobile breakpoint
      let windowResizeFn = function (el) {
        let siteHeight = $(el.target).width();
        if (siteHeight > 990) {
          self._resetMenu();
          levelTwo.removeClass('tb__level--open').hide();
          el.target.removeEventListener('resize', windowResizeFn);
        }
      };

      /* Because we have all the links exposed on the home page it didn't
      make sense to use ajax to load the categories on mobile when a new
      tab was selected because they were already there. This also fixed
      the issue of disappearing navs between breakpoints. If it's not the
      homepage, then use the ajax */
      tabs.on('click', tabFn);

      //Fire window resize event only after window is done resizing.
      let resizeId;
      $(window).on('resize', function (el) {
        clearTimeout(resizeId);
        resizeId = setTimeout(windowResizeFn(el), 500);
      });
      $('.tb-addtl__link--toggle').on('click tap', function () {
        const toggle = $(this);
        toggle.hasClass('tb-addtl__link--collapsed') ? self._toggleAddtlLinks(toggle, 'closed') : self._toggleAddtlLinks(toggle, 'open');
      });

      // Couldn't get jquery breakpoints to work with this.
      flyouts.on('touchend', function (e) {
        const flyout = $(this),
          isOpen = flyout.data('tbFlyout') == 'open';
        if ($(window).width() > 992 && $(window).width() < 1300) {
          e.preventDefault();
          self._closeMenu(flyout);
          if (!isOpen) {
            self._openMenu(flyout);
          }
        }
      });
    },
    _initHoverIntent: function () {
      //Moved to it's own function because it needs to be initialized after ajax.
      const self = this;

      // Toggle the menus based on mouse hovering. jquery.hoverIntent.js is
      // used instead of the native jQuery.hover because it accounts for mouse
      // velocity and more accurately handles when a user is trying to open a menu
      $('[data-tb-flyout]').hoverIntent({
        over: function (e) {
          if (self._checkMobile()) {
            return;
          }
          self._closeMenu($(this).parent());
          self._openMenu($(this));
        },
        out: function (e) {
          let isOverFlyout = $(e.relatedTarget).parents('.tb__level--open').length === 1,
            onTier1 = $(e.relatedTarget).is('[data-tb-flyout]');
          if (isOverFlyout || self._checkMobile() || onTier1) {
            return;
          }
          self._closeMenu($(this).parent());
        },
        timeout: 200
      });

      //HoverIntent for hovering off the menu
      $('[data-level=2]').hoverIntent({
        over: function () {},
        out: function (e) {
          let isTier1 = $(e.relatedTarget).is('[data-tb-flyout]');
          if (e.relatedTarget == null || self._checkMobile() || isTier1) {
            return;
          }
          self._closeMenu($(this).parent());
        },
        timeout: 200,
        interval: 0
      });
      if (self._isHomePage()) {
        $('[data-tb-tab]').hoverIntent({
          over: function () {
            if (!$('html').hasClass('active')) {
              //only for desktop
              const department = $(this).data('navCategory');
              $('[data-tb-tab]').removeClass('tb-ps-tabs__link--active');
              $(this).addClass('tb-ps-tabs__link--active');
              $('[data-level="1"]').hide();
              $('[data-level=2]').removeClass('tb__level--open');
              $(`[data-department-nav="${department}"]`).show();
            }
          },
          out: function (e) {},
          timeout: 200,
          interval: 25
        });
      }
    },
    _initMenuLinks: function () {
      let self = this;
      // opening a sub level menu
      self.menuItems.forEach(function (el, i) {
        // check if it has a sub level
        let link = $(el).find('> a');
        let subLevel = $(`[aria-labelledby="${link.attr('id')}"]`);
        if (subLevel) {
          $('a', el).on(self.eventtype, function (ev) {
            if (!$('html').hasClass('active')) {
              return;
            }
            let tbLevel = self._closest(el, 'tb__level'),
              level = tbLevel.getAttribute('data-level');
            if (level == 1 && link.attr('data-tb-flyout')) {
              // Stop the first level links from going to the URL
              ev.preventDefault();
              ev.stopPropagation();
              $(tbLevel).addClass('tb__level--overlay');
              self._openMenu(subLevel);
              self._initBackLink(subLevel.find(self.levelBack));
            }
          });
        }
      });
    },
    _initBackLink: function (backLink) {
      let self = this;
      backLink.one(self.eventtype, function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        let level = $(this).closest('.tb__level').data('level');
        self.level = level - 1;
        self.level === 0 ? self._resetMenu() : self._closeMenu();
      });
    },
    _openMenu: function (subLevel) {
      let self = this;
      if (self._checkMobile()) {
        // increment level depth
        ++self.level;
        // add class tb--pushed to main wrapper if opening the first time
        if (self.level === 1) {
          $(self.wrapper).addClass('tb--pushed');
          self.open = true;
        }

        // add class tb__level--open to the opening level element
        $(subLevel || self.levels[0]).addClass('tb__level--open');
        if (self.level === 2) {
          //Add class to prevent closing animation
          //Nav.js line 88
          $(subLevel).addClass('tb--sublevel');
        }
      } else {
        const menuId = $(subLevel).attr('id'),
          menuToOpen = $(`[aria-labelledby="${menuId}"]`),
          navCategory = $(subLevel).parents('.tb-navbar__nav');

        //Set self.levels to current powersport
        self.levels = navCategory;
        subLevel.data('tbFlyout', 'open');
        menuToOpen.addClass('tb__level--open');
        $('#my-garage').removeClass('show');
      }
    },
    // close sub menus
    _closeMenu: function ($menuList) {
      let self = this;
      if (self._checkMobile()) {
        self._resetLevels();
      } else {
        $menuList.data('tbFlyout', 'closed');
        //sublevel only shows on mobile
        $('[data-level=2]:not(.tb--sublevel)').removeClass('tb__level--open');
      }
    },
    // close the menu
    _resetMenu: function () {
      let self = this;
      self.level = 0;
      $(self.wrapper).removeClass('tb--pushed');
      $('html').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function (e) {
        self._resetLevels();
        $('#tab-menu').css('margin-top', 0);
      });
      self.open = false;
      self.getMobileCategories($(self.element)).addClass('l1');
      $('html').removeClass('active');
      $('#overlay, .footer-outer').removeClass('tb--pushed');

      //Close the additional links account, garage, etc
      const addtlToggles = $('[data-level=1]').find('.tb-addtl__link--toggle');
      self._toggleAddtlLinks(addtlToggles, 'open');
    },
    _toggleAddtlLinks: function (toggle, toggleState) {
      toggleState === 'open' ? toggle.addClass('tb-addtl__link--collapsed').next().slideUp() : toggle.removeClass('tb-addtl__link--collapsed').next().slideDown();
    },
    _changeTabs: function ($tab) {
      let currentTab = $tab.siblings('.tb-ps-tabs__link--active');
      currentTab.removeClass('tb-ps-tabs__link--active').attr('aria-selected', 'false');
      $tab.addClass('tb-ps-tabs__link--active').attr('aria-selected', 'true');
    },
    // Removes any open levels and sets the first level to be open
    _resetLevels: function () {
      const self = this;
      $('[data-level=1]').removeClass('tb__level--overlay');
      $('[data-level=2]').removeClass('tb__level--open tb--sublevel');
    },
    // returns the closest element to 'e' that has class "classname"
    _closest: function (e, classname) {
      let self = this;
      if ($(e).hasClass(classname)) {
        return e;
      }
      return e.parentNode && self._closest(e.parentNode, classname);
    },
    _isHomePage: function () {
      if ($('.nav-main[data-stick]').length) {
        return true;
      }
    },
    _checkMobile: function () {
      let siteHeight = $(window).width();
      if (siteHeight < 990 || $('html').hasClass('active')) {
        return true;
      }
    },
    getMobileCategories: function ($element) {
      // finds the categories for the selected department as a jquery object
      const activeCategory = $element.find('.tb-ps-tabs__link--active').data('navCategory'),
        $categories = $element.find(`.tb-navbar__nav[data-department-nav="${activeCategory}"]`);
      return $categories;
    },
    changeContents: function (newContents) {
      let self = this;
      self._resetLevels();
      self.element.querySelector('#mobile-menu').innerHTML = newContents;
      $('[data-level=2]').show();
      self.levels = $('.tb__level', self.element);
      self.menuItems = Array.prototype.slice.call(self.element.querySelectorAll(self.settings.linkSelector));
      self.levelBack = $(`.${self.settings.backClass}`, self.element);
      self._initMenuLinks();
      self.level = 1;
      self.getMobileCategories($(self.element)).addClass('tb__level--notrans tb__level--open');
      /* Notrans removes the transition. It caused some weird behavior but we want to
      reenable it very soon after so clicking the first level nav will animate correctly */
      setTimeout(function () {
        $('[data-department-nav]').removeClass('tb__level--notrans');
      }, 30);
    },
    changeCategories: function ($tab) {
      let self = this;
      let tabControls = $tab.attr('aria-controls'),
        tabToOpen = $(`#${tabControls}`),
        tabNavCategory = $tab.data('navCategory'),
        currentTab = $tab.parent().children('.tb-ps-tabs__link--active'),
        currentTabControls = currentTab.attr('aria-controls'),
        currentTabNavCategory = currentTab.data('navCategory');
      if (tabNavCategory !== currentTabNavCategory) {
        self._changeTabs($tab);

        //Set self.levels to current powersport
        self.levels = tabToOpen;
        tabToOpen.addClass('tb__level--open').show();
        $(`#${currentTabControls}`).addClass('tb__level--notrans').hide().removeClass('tb__level--open l1 tb__level--overlay');
        $('[data-level="2"]').removeClass('tb__level--open tb--sublevel');
        const categoryAddtlToggle = $(`#${currentTabControls}`).find('.tb-addtl__link--toggle');
        self._toggleAddtlLinks(categoryAddtlToggle, 'open');
        setTimeout(function () {
          $('[data-department-nav]').removeClass('tb__level--notrans');
        }, 30);
      } else {
        tabToOpen.addClass('tb__level--open').show();
      }
    },
    replaceCategories: function (tab) {
      let self = this;
      let spinner = new Spinner(),
        $tab = $(tab);
      menu = $('#tab-menu'), navCategory = $tab.data('navCategory');
      $.ajax({
        url: `/menu${tab.getAttribute('href')}`,
        beforeSend: function () {
          DK.displayBlocker(menu, false);
          spinner.spin(menu.get()[0]);
          $('[data-tb-tab]').removeClass('tb-ps-tabs__link--active').attr('aria-selected', 'false');
          $tab.addClass('tb-ps-tabs__link--active').attr('aria-selected', 'true').attr('aria-controls', `#fo- ${navCategory}`);
        },
        complete: function () {
          DK.hideBlocker(menu);
          spinner.stop();
        }
      }).always(function (data) {
        self.changeContents(data.menu);
        self._initAddtlEvents();
      });
    }
  });

  // A really lightweight plugin wrapper around the constructor,
  // preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    this.each(function () {
      if (!$.data(this, `plugin_${pluginName}`)) {
        $.data(this, `plugin_${pluginName}`, new Plugin(this, options));
      }
    });

    // chain jQuery functions
    return this;
  };
})(jQuery, window, document);