(function ($) {
  // Set focus on the search input when it is shown
  $('#mobile-search')[0].addEventListener('shown.bs.collapse', function (event) {
    $(event.currentTarget).find('input[name=q]').trigger('focus');
  });
})(jQuery);
$(function () {
  $(document).on('click', '#cart-header .remove-link', function (e) {
    e.preventDefault();
    let line = $(this).data('lineItem');
    let spinner = new Spinner();
    const $form = $('#cart-header form');
    $.ajax({
      type: 'POST',
      async: true,
      url: `/cart/remove/${line}`,
      contentType: 'application/json; charset=utf-8',
      context: this,
      beforeSend: function () {
        DK.displayBlocker($form);
        spinner.spin($form.get()[0]);
      },
      complete: function () {
        DK.hideBlocker($form);
        spinner.stop();
      }
    }).done(function (data) {
      let cartEmpty = data.cartItems == null || data.cartItems.length == 0;
      if (DK.Tracking.Listrak.isAvailable()) {
        if (cartEmpty) {
          _ltk.SCA.ClearCart();
        } else {
          $.each(data.cartItems, function (index, item) {
            if (item.inventoryStatusCodeId !== 'MULTI_PARCEL') {
              _ltk.SCA.AddItemWithLinks(item.skuId, item.quantity, item.amount.unitAmount.toString(), item.info.sku.name, item.info.image.primaryImagePath, item.seoUrl);
            }
          });
          _ltk.SCA.Submit();
        }
      }
      $(this).closest('.item').remove();
      if (cartEmpty) {
        $('.cart-empty').show();
        $('.cart-items').hide();
        $('.card-footer').hide();
      }

      //Format order total to currency
      let formatTotal = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      $('.cart-message').text(data.cartTotalQty);
      $('.cart-subtotal #subtotal').text(formatTotal.format(data.cartTotal));
    });
  });
  $(document).ajaxError(DK.ajaxAuthenticationErrorHandler);
});

/**
 * Sets a cookie when one of the top level navigation links or one of the
 * 'How Do You Ride' images are selected. This cookie is meant to provide
 * a persistent navigation by keeping the last selected category active.
 */
$('a[data-nav-category]').on('click', function (e) {
  Cookies.set('selected_header', $(e.currentTarget).data('navCategory'), {
    path: '/',
    expires: 7,
    secure: true
  });
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'Navigation', $(e.currentTarget).data('navCategory'), e.currentTarget.text.trim());
  }
});

/**
 * Sets a cookie when one of the secondary navigation links are selected.
 * This cookie is meant to provide a persistent navigation by keeping the
 * last selected category active.
 */
$('.tb-navbar .tb-navbar__link').on('click', function (e) {
  let $selectedTab = $('#tab-menu .tb-ps-tabs__link--active');
  if (typeof ga !== 'undefined') {
    let $target = $(e.currentTarget);
    let label = $target.data('gaLabel');
    if (!label) {
      label = $target.text().trim();
    }
    let parent = $target.parents('[data-level=2]').prev().find('[data-tb__level=open]').text().trim();
    if (parent) {
      label = `${parent}/${label}`;
    }
    ga('send', 'event', 'Navigation', $selectedTab.data('navCategory'), label);
  }
});
(function ($) {
  // Track when the mobile search dropdown is opened and closed
  $('#mobile-search')[0].addEventListener('shown.bs.collapse', () => {
    ga('send', 'event', 'Header Links - Mobile', 'open', 'Open Search Dropdown');
  });
  $('#mobile-search')[0].addEventListener('hidden.bs.collapse', () => {
    ga('send', 'event', 'Header Links - Mobile', 'close', 'Close Search Dropdown');
  });
})(jQuery);

//Prevent the my garage dropdown from closing when clicking inside the dropdown
$(document).on('click', '#my-garage', function (e) {
  e.stopPropagation();
});
$(function () {
  $('#tab-menu').tabitha({
    type: 'cover',
    levelSpacing: 0,
    trigger: '#tb-trigger',
    pusher: '.site-outer',
    linkSelector: '.tb-navbar .tb-navbar__item'
  });
});