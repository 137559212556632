//functionality for the footer accordions in the tablet/mobile view.//
(function ($) {
  $('#footer-2 .footer-heading').next().addClass('d-nope');
  $('.footer-heading').on('click', function () {
    $(this).parent('.nav').toggleClass('open');
    $(this).next().slideToggle().toggleClass('d-nope');
  });
  $(window).on('greaterEqualTo-lg', function () {
    $('.footer-heading').parent('.nav').removeClass('open').find('ul').css('display', '').addClass('d-nope');
  });
})(jQuery);
(function ($) {
  // Send analytics events when a link in the footer is clicked.
  $('#footer-2').find('a').on('click', function (e) {
    let $link = $(e.currentTarget),
      header = $link.parents('ul').prev().text();
    return ga('send', 'event', 'Footer - Links', 'click', 'Section: ' + header + ', URL:  ' + $link.attr('href'));
  });
})(jQuery);