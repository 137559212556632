(function ($, window) {
  const pluginName = 'searchAutocomplete',
    publicMethods = ['destroy'];
  $.fn.searchAutocomplete = function (options) {
    const args = arguments,
      dataAttr = `plugin_${pluginName}`;
    return this.each(function (index, element) {
      if (typeof options === 'string') {
        if (publicMethods.indexOf(options) > -1) {
          const searchAutocomplete = $.data(element, dataAttr),
            method = options;
          searchAutocomplete[method].apply(searchAutocomplete, Array.prototype.slice.call(args, 1));
        } else {
          throw new SyntaxError(`Invalid method "${options}"`);
        }
      } else if (!$.data(this, dataAttr)) {
        const searchAutocomplete = Object.create(SearchAutocomplete);
        searchAutocomplete.init(options, element);
        $.data(this, dataAttr, searchAutocomplete);
      }
      return this;
    });
  };
  $.fn.searchAutocomplete.options = {
    $formId: undefined,
    // The search bar that is being targeted
    $searchBar: undefined,
    $suggestDiv: undefined,
    $resetBtn: undefined,
    $closeCanvasBtn: undefined,
    $searchBtn: undefined,
    $offcanvasDiv: document.getElementsByClassName('msearch__offcanvas')[0],
    // The number of milliseconds to waiy after the last character
    // is typed before sending the GetSuggestions request
    charDelay: 500,
    // The timer function to use for handling user input
    keyPressTimer: undefined,
    screenType: undefined,
    // Common key codes used for events
    KEY_DOWN: 'ArrowDown',
    KEY_UP: 'ArrowUp',
    KEY_ESCAPE: 'Escape',
    KEY_ENTER: 'Enter',
    KEY_BACK: 'Backspace'
  };
  let SearchAutocomplete = {
    // Initialize the SearchAutocomplete module by attaching any events
    // that we need to run an autocomplete.
    init: function (options, element) {
      const self = this;
      self.options = $.extend({}, $.fn.searchAutocomplete.options, options);
      self.element = element;
      self.$element = $(element);

      /* Since there are always two instances of this plugin,
      give them unique ids */
      self._generateid(element);
      self._checkWindowSize();
      self.registerSelectors();
      self.attachEvents();
    },
    registerSelectors: function () {
      const self = this;
      self.options.$searchBar = self.options.$formId.find('.hsearch__input');
      self.options.$suggestDiv = self.options.$formId.find('.hsearch__list__wrapper');
      self.options.$resetBtn = self.options.$formId.find('.hsearch__btn--reset');
      self.options.$closeCanvasBtn = self.options.$formId.find('.hsearch__btn--close');
      self.options.$searchBtn = self.options.$formId.find('.hsearch__btn--search');
    },
    attachEvents: function () {
      const self = this;
      self.options.$searchBar.on('keyup', function (event) {
        let searchTerm = $(event.currentTarget).val();
        self.toggleXmark('show');
        switch (event.key) {
          // Select an option from the suggestion menu
          // using the arrow keys
          case self.options.KEY_UP:
          case self.options.KEY_DOWN:
            event.preventDefault();
            self.selectSuggestion(event.key);
            break;
          // Check to see if enter was pressed, if it was,
          // grab the currently selected item (if there is one)
          // and use that as the search term.
          case self.options.KEY_ENTER:
            event.preventDefault();
            self.toggleSuggestionDiv('hide');
            self.doSearch();
            break;
          // Hide the suggestions when the escape key is pressed
          case self.options.KEY_ESCAPE:
            event.preventDefault();
            self.toggleSuggestionDiv('hide');
            self.toggleXmark('hide');
            break;
          // If you press the back key, redo the search with that query
          // If there is no value, close the suggestion box
          case self.options.KEY_BACK:
            if (searchTerm === '') {
              self.toggleSuggestionDiv('hide');
              self.toggleXmark('hide');
            } else {
              self.generateSuggestion();
            }
            break;
          // If we aren't handling special key presses, generate the
          // query and populate the drop down according to the
          // data that is returned.
          default:
            self.options.keyPressTimer = setTimeout(self.generateSuggestion(), self.options.charDelay);
        }
      });
      self.options.$searchBar.on('focus', searchFocus);
      self.options.$resetBtn.on('click', resetInput);
      self.options.$offcanvasDiv.addEventListener('shown.bs.offcanvas', shownOffcanvas);
      self.options.$offcanvasDiv.addEventListener('show.bs.offcanvas', showOffcanvas);
      self.options.$offcanvasDiv.addEventListener('hide.bs.offcanvas', hideOffcanvas);
      $(document).on('mouseenter', '.hsearch__list__item', highlightActive);
      $(document).on('mouseup', hideSuggestions);
      $(window).on('resize', windowResize);
      function hideSuggestions(event) {
        const $suggestDiv = self.options.$suggestDiv;
        // Check if mobile or desktop and execute only on desktop
        if (self.options.screenType !== 'mobile') {
          // If we aren't clicking on the div nor the search bar, hide the div.
          if (!$suggestDiv.is(event.target) && $suggestDiv.has(event.target).length == 0 && !self.options.$searchBar.is(event.target)) {
            self.toggleSuggestionDiv('hide');
            self.toggleXmark('hide');
          }
        }
      }

      /* If you focus on the search input and there's already a query,
      then generate suggestions and show the wrapper. If there are already
      suggestions present, then just show the wrapper without generating
      new suggestions */
      function searchFocus() {
        const hasSuggestions = self.options.$suggestDiv.children('.hsearch__list__item').length;
        if (self.options.$searchBar.val() !== '') {
          if (hasSuggestions === 0) {
            self.generateSuggestion();
          }
          self.toggleSuggestionDiv('show');
          self.toggleXmark('show');
        }
      }

      /* On reset, hide the reset button, empty the input value
      and focus the input again */
      function resetInput() {
        self.toggleXmark('hide');
        self.options.$searchBar.val('').focus();
        self.toggleSuggestionDiv('hide');
      }

      /* Add background to whatever item your mouse is hover over */
      function highlightActive() {
        self.options.$suggestDiv.find('.hsearch__list__item').removeClass('hsearch__list__item--active');
        $(this).addClass('hsearch__list__item--active');
      }

      /* Mobile -- On page load the cursor is at the beginning of the search query since the
      value is loaded before the focus is called. This determines the query length
      and sets the cursor to the end -- focus needs to be called after the
      show.bs.offcanvas event*/
      function shownOffcanvas() {
        self.options.$searchBar.focus();
        let queryLength = self.options.$searchBar.val().length;
        self.options.$searchBar[0].setSelectionRange(queryLength, queryLength);
      }

      // Mobile -- Executed when the search button is click, before the canvas shows
      function showOffcanvas() {
        // Fix the body's position to prevent background scrolling on IOS devices
        $('body').css('position', 'fixed');

        // If search isn't empty, show the reset button and generate suggestions
        if (self.options.$searchBar.val() !== '') {
          self.toggleXmark('show');
          self.generateSuggestion();
        }
      }

      // Mobile -- executed when hide button is clicked before canvas hides
      function hideOffcanvas() {
        //Remove the body's fixed positioning to allow scrolling
        $('body').css('position', 'relative');
      }

      /* When resizing window, if it's above the mobile
      threshold then hide the offcanvas element. Use timer
      to execute function so it doesn't run constantly */
      function windowResize() {
        let resizeTimer;
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          if (self.options.screenType !== 'mobile') {
            self.toggleSuggestionDiv('hide');

            /*If you resize to desktop, hide the search canvas
            threw and error if the offcanvas hadn't been initialized yet
            so had to add the getOrCreate instead of just get */
            bootstrap.Offcanvas.getOrCreateInstance(self.options.$offcanvasDiv).hide();
          }
        }, 200);
        //Check window size to determine new screen type
        self._checkWindowSize();
      }
    },
    selectSuggestion: function (keyCode) {
      const self = this;
      const $items = self.options.$suggestDiv.find('.hsearch__list__item'),
        $activeItem = self.options.$suggestDiv.find('.hsearch__list__item.hsearch__list__item--active');
      let currentIndex = $items.index($activeItem),
        hasCurrentSelection = currentIndex !== -1,
        selectedIndex = currentIndex;
      if (keyCode === self.options.KEY_DOWN) {
        if (!hasCurrentSelection || currentIndex === $items.length - 1) {
          // Select the first item in the list when KEY_DOWN is
          // pressed on the last item in the list
          selectedIndex = 0;
        } else {
          // Select to the next item in the list
          selectedIndex += 1;
        }
      } else if (keyCode === self.options.KEY_UP) {
        if (!hasCurrentSelection || currentIndex < 0) {
          // Select the last item in the list when KEY_UP is
          // pressed on the first item in the list
          selectedIndex = $items.length;
        } else {
          // Select the previous item in the list
          selectedIndex -= 1;
        }
      }
      let newQuery = $items.eq(selectedIndex).text();
      $items.removeClass('hsearch__list__item--active');
      $items.eq(selectedIndex).addClass('hsearch__list__item--active');

      //Change input value as you arrow up and down
      self.options.$searchBar.val(newQuery);
    },
    generateSuggestion: function () {
      const self = this;
      let searchTerm = self.options.$searchBar.val();
      if (searchTerm === '') {
        // Hide the suggestions and do not send a request to Hawk
        // if there is the search term is empty
        self.toggleSuggestionDiv('hide');
        return;
      }

      // Request suggestion data from Hawk and display the suggestions
      $.ajax({
        url: '/autocomplete',
        method: 'GET',
        // dataType: "jsonp",
        data: {
          query: searchTerm,
          mmyId: ''
        },
        cache: false
      }).done(function (data) {
        /* Clear the body of all suggestion before populating new ones
        	to avoid duplicate entries */
        self.toggleSuggestionDiv('hide');
        self.options.$suggestDiv.children('.hsearch__list').empty();
        // self.populateBaseSection(self.options.$suggestDiv, data, searchTerm)
        data.correctlySpelled === false ? self.alternateSuggestions(self.options.$suggestDiv, data, searchTerm) : self.populateBaseSection(self.options.$suggestDiv, data, searchTerm);
      });
    },
    populateBaseSection: function ($body, data, searchTerm) {
      const self = this,
        listContainer = $body.children('.hsearch__list');
      //If the 'did you mean' is present remove it
      if ($('.hsearch__alt').length !== 0) {
        $('.hsearch__alt').remove();
      }
      if (data.resultCount === 0) {
        return;
      } else {
        // Populate with generated HTML
        $.each(data.hits, function (i, item) {
          let li = $('<li>').addClass('hsearch__list__item'),
            a = $('<a>').addClass('hsearch__list__link').attr('href', item.url).html(self._matchString(searchTerm, item.category)).on('click', function (event) {
              event.preventDefault();
              self.doSearch();
            });
          listContainer.append(li.html(a));
        });
        self.toggleSuggestionDiv('show');
      }
    },
    alternateSuggestions: function ($body, data, searchTerm) {
      const self = this,
        listContainer = $body.children('.hsearch__list');
      if (data.correctlySpelled === false) {
        let didYouMean = $('<div>').addClass('hsearch__alt').text('Did you mean');
        if ($('.hsearch__alt').length === 0) {
          $body.prepend(didYouMean);
        }
        $.each(data.spellingSuggestions, function (i, item) {
          let li = $('<li>').addClass('hsearch__list__item');
          a = $('<a>').addClass('hsearch__list__link').attr('href', item.url).html(self._matchString(searchTerm, item.suggestion)).on('click', function (event) {
            event.preventDefault();
            self.doSearch();
          });
          listContainer.append(li.html(a));
        });
        self.toggleSuggestionDiv('show');
      }
    },
    doSearch: function () {
      // Find the active option and execute search on that url
      const self = this,
        hasCurrentSelection = $('.hsearch__list__item--active').length !== 0;
      if (hasCurrentSelection) {
        // update the input text if there is an autocomplete selection
        self.options.$searchBar.val($('.hsearch__list__item--active a').text());
      }
      self.options.$formId.trigger('submit');
    },
    //Either hide or show the suggestions dropdown
    toggleSuggestionDiv: function (status) {
      const self = this;
      let show = status;
      show === 'show' ? self.options.$suggestDiv.show() : self.options.$suggestDiv.hide();
    },
    toggleXmark: function (status) {
      // Toggles the x button that clears the input
      const self = this;
      let show = status;
      show === 'show' ? self.options.$resetBtn.addClass('hsearch__btn--reset--show') : self.options.$resetBtn.removeClass('hsearch__btn--reset--show');
    },
    //Match the typed text to the autocomplete items and make that part bold
    _matchString: function (query, item) {
      /* If the string typed matches an autocomplete suggestion,
      make that of the suggestion bold */
      var regex = new RegExp('(' + query.trim() + ')', 'i');
      return item.replace(regex, '<span class="fw-normal">$1</span>');
    },
    /* Create a unique ID for the handlebar selector. Just in case
    		there ends up being more than one instance on a page */
    _generateid: function (element) {
      let self = this;
      if (!element.id) {
        element.id = `hsearch-${Math.random().toString(36).substring(2, 6)}`;
      }
      self.options.$formId = $(`#${element.id}`);
      return element;
    },
    /* Returns whether breakpoint is below large or not
    needed because our breakpoints plugin isn't working */
    _checkWindowSize: function () {
      const self = this;
      $(window).width() < 991 ? self.options.screenType = 'mobile' : self.options.screenType = 'desktop';
    }
  };
})(jQuery, window);
$('[searchForm]').searchAutocomplete();