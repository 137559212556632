(function ($, window) {
  const pluginName = 'shameOnYou',
    encodedSpace = '-';
  publicMethods = ['replaceNaughtyWords'];
  function ShameEntry(jsonData, multiWordOnly) {
    const filterFunc = function (str) {
      if (multiWordOnly === true) {
        return str.indexOf(encodedSpace) > -1;
      } else if (multiWordOnly === false) {
        return str.indexOf(encodedSpace) < 0;
      } else {
        return true;
      }
    };
    this.badWords = jsonData.match.filter(filterFunc).map(str => this.decode(str)).map(str => new RegExp(`(\\W+|^)${str}(\\W+|$)`, 'ig'));
    this.goodWords = jsonData.alternates;
  }
  ShameEntry.prototype.decode = function (str) {
    return [...str].map(c => String.fromCharCode(c.charCodeAt() ^ 13)).join('');
  };
  ShameEntry.prototype.isMultipleWords = function () {
    return this.badWords.filter(word => word.indexOf(' ') > -1).length > 0;
  };
  ShameEntry.prototype.hasBadWord = function (value) {
    for (matcher of this.badWords) {
      if (value.match(matcher)) {
        return matcher;
      }
    }
  };
  ShameEntry.prototype.chooseGoodWord = function () {
    return this.goodWords[Math.floor(Math.random() * this.goodWords.length)];
  };
  ShameEntry.prototype.replaceBadWord = function (value, matcher) {
    matcher = matcher === undefined ? this.hasBadWord(value) : matcher;
    const goodWord = this.chooseGoodWord();
    return value.replace(matcher, `$1${goodWord}$2`);
  };
  $.fn.shameOnYou = function (options) {
    const args = arguments,
      dataAttr = `plugin_${pluginName}`,
      publicMethods = ['replaceNaughtyWords'];
    return this.each(function (index, element) {
      if (typeof options === 'string') {
        if (publicMethods.indexOf(options) > -1) {
          const shameOnYou = $.data(element, dataAttr),
            method = options;
          shameOnYou[method].apply(shameOnYou, Array.prototype.slice.call(args, 1));
        } else {
          throw new SyntaxError(`Invalid method "${options}"`);
        }
      } else if (!$.data(this, dataAttr)) {
        const shameOnYou = Object.create(ShameOnYou);
        shameOnYou.init(options, element);
        $.data(this, dataAttr, shameOnYou);
      }
      return this;
    });
  };
  $.fn.shameOnYou.options = {
    KEY_DOWN: '229',
    KEY_ENTER: 'Enter',
    KEY_SPACE: 'Space'
  };
  let ShameOnYou = {
    init: function (options, element) {
      const self = this;
      self.options = $.extend({}, $.fn.shameOnYou.options, options);
      self.element = element;
      self.$element = $(element);
      self.shameDictionary = null;
      $.ajax({
        url: '/dkmobile/javascripts/layout/shamedictionary.json',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8'
      }).done(function (data) {
        self.shameDictionary = data.filter(obj => obj.match.filter(str => str.indexOf(encodedSpace) > -1).length > 0).map(obj => new ShameEntry(obj, true));
        self.shameDictionary = self.shameDictionary.concat(data.filter(obj => obj.match.filter(str => str.indexOf(encodedSpace) == -1).length > 0).map(obj => new ShameEntry(obj, false)));
        self.firstWords = new Set([...data.flatMap(e => e.match).filter(str => str.indexOf(encodedSpace) > -1)
        // we should not need to duplicate this code, but for reasons not
        // comprehensible to mere mortals, when we declare decode as a function
        // at the top level of the plugin closure, right before ShameEntry, we
        // can use ShameEntry(), but decode() says 'decode is not defined'?!?
        .map(str => [...str].map(c => String.fromCharCode(c.charCodeAt() ^ 13)).join('')).map(str => str.match(/\w+/)[0]).map(str => new RegExp(`(^|\\W+)${str}\\W*$`))]);
      });
      self.initEvents();
    },
    initEvents: function () {
      let self = this;
      $('#comment').on('keyup touchend', function (e) {
        let kc = e.code;
        let textarea = e.currentTarget;
        // 229 = Any key?
        if (!kc || e.keyCode == 229) {
          let ss = textarea.selectionStart - 1;
          let ssv = ss || 0;
          let char = textarea.value.substr(ssv, 1);
          kc = char.charCodeAt(0);
        }
        //Enter = 13 Space = 32
        if (kc == self.options.KEY_SPACE || kc == self.options.KEY_ENTER) {
          self.replaceNaughtyWords();
        }
      });
      document.querySelector('#comment').addEventListener('paste', function (e) {
        e.preventDefault();
        const text = (e.clipboardData || window.clipboardData).getData('text'),
          textArea = e.currentTarget,
          selectionStart = textArea.selectionStart,
          selectionEnd = textArea.selectionEnd,
          newText = self.replaceNaughtyWordsInString(text, true),
          newSelection = selectionEnd + newText.length;
        textArea.setRangeText(newText, selectionStart, selectionEnd);
        textArea.setSelectionRange(newSelection, newSelection);
        return false;
      });
    },
    replaceNaughtyWordsInString: function (text, processAll) {
      processAll = processAll === undefined ? false : processAll;
      const self = this;
      let newContent = text;
      if (processAll) {
        for (const shameonyou of self.shameDictionary) {
          const matcher = shameonyou.hasBadWord(newContent);
          if (matcher) {
            newContent = shameonyou.replaceBadWord(newContent, matcher);
          }
        }
      } else {
        for (const firstWord of self.firstWords) {
          if (text.match(firstWord)) {
            return newContent;
          }
        }
        for (const shameonyou of self.shameDictionary) {
          const matcher = shameonyou.hasBadWord(newContent);
          if (matcher) {
            newContent = shameonyou.replaceBadWord(newContent, matcher);
            break;
          }
        }
      }
      return newContent;
    },
    replaceNaughtyWords: function (processAll) {
      const $comment = $('#comment'),
        newText = this.replaceNaughtyWordsInString($comment.val(), processAll);
      $comment.val(newText);
    }
  };
})(jQuery, window);
function initFeedbackEvents() {
  $('#feedback-form').shameOnYou();
  $('#customerFeedbackCategoryId input').off('change');
  $('#customerFeedbackCategoryId input').on('change', function (e) {
    let input = $(e.currentTarget),
      inputSiblings = input.parent().siblings().find('input');
    inputSiblings.removeAttr('checked');
    input.attr('checked', 'true');
    let category = this.value;
    message = $('label[for="comment"]').data(`message-${category}`);

    // hide all the extra feedback fields
    $('.js-feedback-extra').addClass('d-none');
    // reset all the extra feedback fields
    $('.js-feedback-ride input').val('');
    $('.js-feedback-items input').val('');
    $('.js-feedback-order-number input').val('');
    $('.js-feedback-customer-service input').prop('checked', false);
    $('.js-feedback-unsubscribe input').prop('checked', false);

    // no new fields for 1(general) or 2(website)
    if (category == 3) {
      $('.js-feedback-ride, .js-feedback-items').removeClass('d-none');
    } else if (category == 4) {
      $('.js-feedback-unsubscribe').removeClass('d-none');
    } else if (category == 5) {
      $('.js-feedback-order-number, .js-feedback-customer-service').removeClass('d-none');
    }
    $('label[for="comment"]').text(message);
  });
  $('#feedback-form').off('submit');
  $('#feedback-form').on('submit', function (e) {
    e.preventDefault();

    // Check for naughty words
    $('#feedback-form').shameOnYou('replaceNaughtyWords', true);
    $('#googleClientId').val($('body').attr('data-google-client-id'));
    if (DK.Tracking.Listrak.isAvailable()) {
      _ltk.SCA.CaptureEmail('feedback-email');
    }
    let $form = $('#feedback-form');
    let spinner = new Spinner();
    $.ajax({
      type: 'POST',
      url: `${$form.attr('action')}.json`,
      data: $form.serialize(),
      beforeSend: function () {
        DK.displayBlocker('#feedback-form');
        spinner.spin($('#feedback-form').get()[0]);
      },
      complete: function () {
        DK.hideBlocker('#feedback-form');
        spinner.stop();
      }
    }).done(function (data) {
      $('#feedback-form').html(data);
      $('#feedback').find('.modal-title').text('Thanks for your feedback!');
      // setTimeout($.modal.close, 5000);
    }).fail(function (data) {
      $('#feedback-form').html(data.responseText);
      initFeedbackEvents();
    });
    return false;
  });
}
initFeedbackEvents();