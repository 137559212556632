(function ($, window) {
  const pluginName = 'morty';
  $.fn.morty = function (options) {
    return this.each(function (index, element) {
      const args = arguments,
        dataAttr = `plugin_${pluginName}`;
      if (typeof options === 'string') {
        if (publicMethods.indexOf(options) > -1) {
          const morty = $.data(element, dataAttr),
            method = options;
          morty[method].apply(morty, Array.prototype.slice.call(args, 1));
        } else {
          throw new SyntaxError(`Invalid method "${options}"`);
        }
      } else if (!$.data(element, dataAttr)) {
        const morty = Object.create(Morty);
        morty.init(options, element);
        $.data(element, dataAttr, morty);
      }
      return this;
    });
  };
  $.fn.morty.options = {
    uri: '/ride-finder.json',
    visibilityPollingInterval: 100,
    //Is it a modal?
    modal: false,
    //Keep lists collapsed initially
    startCollapsed: true
  };
  let Morty = {
    init: function (options, element) {
      const self = this;
      self.element = element;
      self.options = $.extend({}, $.fn.morty.options, options);
      self.$form = undefined;
      self.$lastSelect = undefined;
      self.$select = undefined;
      self.removeEvents(); //remove click events so they don't get duplicated
      self.generateId(element);
      self.initEvents();
      self.$mobileBreakPoint = $('html').width();
      self.$collapse = undefined;
      self.$selectValue = '<span class="rf__select-value"></span>';
    },
    initEvents: function () {
      const self = this;

      // When you click a dropdown
      $(self.element).on('click', '.rf__collapse-list:not(.-disabled)', function (event) {
        // $(self.element).find('.rf__collapse-list:not(.-disabled)')
        // 	.on("click", function (event) {
        let $collapse = $(event.currentTarget);
        let $isOpen = $collapse.attr('aria-expanded');
        /*If it's already open, close it,
        otherwise open it and close everything else */
        if ($isOpen === 'true') {
          self.close($collapse);
        } else {
          let $notSelected = $(self.element).find('.rf__collapse-list.-active').not($collapse);
          self.close($notSelected);
          self.show($collapse);
          $collapse.find('.rf__select').scrollTop(0);
        }
      }).on('keydown', '.rf__collapse-list:not(.-disabled)', function (event) {
        let $collapse = $(event.currentTarget);
        self.keydown(event, $collapse);
        let $defaultButton = $collapse.find('button[data-val=-1]'),
          $hasDefaultButton = $defaultButton.length === 1,
          $button = $defaultButton.siblings();
        if ($hasDefaultButton === true && $button.length > 7) {
          $defaultButton.remove();
        }
      });

      //If you click outside of any dropdowns, close everything.
      $(document).on('click', function (e) {
        if (!self.$form.is(e.target) && self.$form.has(e.target).length === 0) {
          let $isOpen = self.$form.find('.rf__collapse-list.-active');
          self.close($isOpen);
        }
      });

      // When you click a button
      $(self.element).on('click', '.rf__select button:not([data-val=-1])', function (event) {
        event.preventDefault();
        event.stopPropagation();
        self.select(event.currentTarget);
        let $nextSelect = $(event.currentTarget).parents('.rf__collapse-list').attr('data-ride-selector-child');
        if ($nextSelect !== undefined) {
          self.resetOptions($nextSelect);
        }
        self.selectOption(event.currentTarget, $nextSelect);
      }).on('click', '.rf__select button[data-val=-1]', function (event) {
        // you can't click on the default option
        event.preventDefault();
        event.stopPropagation();
      });

      // Sticky Ride selector
      $(function () {
        // https://developers.google.com/web/updates/2017/09/sticky-headers//
        // https://codepen.io/hey-nick/pen/mLpmMV
        let isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);
        if (!isIE && $('[data-stick]').length) {
          const sentinalEl = $('[data-stick]'),
            handler = entries => {
              let headerEl = $('.rf--sticky'),
                $hasStuck = $('.rf--sticky[data-rfs]');
              // entries is an array of observed dom nodes
              // we're only interested in the first one at [0]
              // because that's our .sentinal node.
              // Here observe whether or not that node is in the viewport
              if (!entries[0].isIntersecting) {
                headerEl.addClass('rf--stuck');
                headerEl.removeClass('rf--unstuck');
                if (headerEl.hasClass('rf--stuck')) {
                  headerEl.attr('data-rfs', '');
                }
              } else {
                headerEl.removeClass('rf--stuck');
                if ($hasStuck.length) {
                  headerEl.addClass('rf--unstuck');
                }
              }
            };
          // create the observer
          const observer = new window.IntersectionObserver(handler);
          // give the observer some dom nodes to keep an eye on
          observer.observe(sentinalEl[0]);
        }
      });
    },
    show: function (collapse) {
      const self = this;
      let $collapse = $(collapse),
        $step = $collapse.children('.rf__select-step'),
        $select = $collapse.find('.rf__select'),
        $selectedButton = $select.find('button[data-selected=true]');
      $collapse.removeClass('-disabled').addClass('-active').attr('aria-expanded', 'true');
      $step.addClass('-float');
      $select.slideDown(300).scrollTop();

      // Focus on selected button(if any) when opening//
      if ($selectedButton.length > 0) {
        self.focus($selectedButton);
      }

      // Run the transition
      $select.addClass('-open');
    },
    close: function ($collapse) {
      const self = this;
      let $step = $collapse.children('.rf__select-step'),
        $select = $collapse.find('.rf__select'),
        $value = $select.find('button[data-selected=true]').data('val');
      $collapse.removeClass('-active');

      // If the default option is still selected, remove the float label on close
      if ($value == -1) {
        $step.removeClass('-float');
      }

      // Closing transition happens
      /*if the screen is less than medium, remove the close animation
      because it conflicts when vertical*/
      $collapse.attr('aria-expanded', 'false');
      if (self.$mobileBreakPoint < 768) {
        $select.hide();
      } else {
        $select.slideUp(300);
      }
      if ($value != -1) {
        $select.find('button[data-val=-1]').remove();
      }
    },
    select: function (button, skipClose) {
      const self = this;
      let $selectedButton = $(button).attr('data-selected', 'true'),
        $collapse = $selectedButton.parents('.rf__collapse-list');
      $selectedButton.siblings('[data-val=-1]').attr('data-selected', 'false');
      $collapse.find('.rf__select-step').empty().html(`<span class="rf__select-value">${$selectedButton.text()}</span>`);
      if (skipClose == undefined || !skipClose) {
        self.close($collapse);
      }
    },
    resetValue: function (nextSelect) {
      const self = this;
      let $nextSelect = $(nextSelect).find('.rf__select'),
        $childParent = $nextSelect.parents('.rf__collapse-list'),
        $childStep = $childParent.children('.rf__select-step'),
        $childSelect = $childParent.find('.rf__select'),
        $isOpen = $childParent.attr('aria-expanded');

      // If you click on a previous dropdown, close the shown dropdown
      if ($isOpen === 'true') {
        self.close($childSelect);
      } else {
        $childSelect.scrollTop(0);
        $childSelect.removeClass('-open');
        $childStep.removeClass('-float');
        $childStep.children().remove();
        if ($childParent.attr('data-id') !== 'rs-make') {
          $childParent.addClass('-disabled');
        }
        $childParent.removeClass('-active').attr('aria-expanded', 'false');
      }
    },
    autoSelect: function ($select, $autoButton) {
      const self = this,
        $collapse = $select.parents('.rf__collapse-list');
      const $button = $select.find('button:gt(0)');

      // remove the default button
      $collapse.find('button[data-val=-1]').remove();
      if ($autoButton !== null) {
        self.select($autoButton, true);
      } else {
        // Autoselect if only one option
        self.select($button, true);
      }

      // remove the disabled and float the label without animation
      $collapse.removeClass('-disabled');
      $select.prev().addClass('-float');
    },
    keydown: function (event, collapse) {
      const self = this;
      // Insert accessibility for using arrow keys down a list
      let $collapse = $(collapse),
        $select = $collapse.find('.rf__select'),
        $button = $select.children(),
        $selectedButton = $select.find('[data-selected=true]'),
        $selectedStep = $selectedButton.parent().siblings();

      // if you hit the down key, focus and select the option below
      if (event.which === 40) {
        event.preventDefault();
        if ($selectedButton) {
          $selectedButton.attr('data-selected', 'false');
          //append the selected value span so you can update it via arrow keys
          next = $selectedButton.next();
          if (next.length > 0) {
            $selectedButton = next.attr('data-selected', 'true');
            self.scrollView($selectedButton, 'down');
            self.focus($selectedButton);
            $selectedStep.html(`<span class="rf__select-value">${$selectedButton.text()}</span>`);
          } else {
            $selectedButton = $button.eq(0).attr('data-selected', 'true');
            self.scrollView($selectedButton, 'down');
            self.focus($selectedButton);
          }
        } else {
          $selectedButton = $button.eq(0).attr('data-selected', 'true');
          self.scrollView($selectedButton, 'down');
          self.focus($selectedButton);
        }
      } else if (event.which === 38) {
        // If you hit the up key, do the opposite
        event.preventDefault();
        if ($selectedButton) {
          $selectedButton.attr('data-selected', 'false').removeClass('-active');
          next = $selectedButton.prev();
          if (next.length > 0) {
            $selectedButton = next.attr('data-selected', 'true');
            this.scrollView($selectedButton, 'up');
            this.focus($selectedButton);
            $selectedStep.html(`<span class="rf__select-value">${$selectedButton.text()}</span>`);
          } else {
            $selectedButton = $button.last().attr('data-selected', 'true');
            this.scrollView($selectedButton, 'up');
            this.focus($selectedButton);
          }
        } else {
          $selectedButton = $button.last().attr('data-selected', 'true');
          this.scrollView($selectedButton, 'up');
          this.focus($selectedButton);
        }
      } else if (event.which === 13) {
        // If you hit enter, select that option and open the next select.
        if ($selectedButton.data('val') != -1) {
          this.select($selectedButton);
          this.close(collapse);
        }
      }
    },
    scrollView: function ($collapse, direction) {
      const $select = $collapse.parents('.rf__select'),
        $button = $collapse,
        buttonHeight = $button.outerHeight(),
        selectTop = $select.scrollTop();
      if (direction === 'up') {
        let distance = selectTop - buttonHeight;
        if (distance < 0) {
          distance = 0;
        }
        $select.scrollTop(distance);
      } else if (direction === 'down') {
        let distance = selectTop + buttonHeight;
        $select.scrollTop(distance);
      }
    },
    focus: function ($selectedButton) {
      let $select = $selectedButton.parents('.rf__select');
      $select.scrollTop(0);
      $selectedButton[0].focus();
    },
    defaultOption: function () {
      return '<button data-val="-1" data-selected="true"></button>';
    },
    resetOptions: function (nextSelect) {
      const self = this;
      const $nextDropdown = self.$form.find(`[data-name=${nextSelect}]`);
      let $childParent = $nextDropdown.parents('.rf__collapse-list'),
        $nextChild = $childParent.data('ride-selector-child'),
        $prevParent = $childParent.data('ride-selector-parent'),
        $nextChildDropdown = $(`[data-name=${$nextChild}]`);
      self.collapse = $childParent;
      self.$form.find('.rf__collapse-list').data('autoselect', false);
      self.$form.find(`input[name="${nextSelect}"]`).val('-1');

      /*Account for submodelgroup special snowflake
       * needs to be removed if a different make
       * or ride type is selected */
      if ($prevParent === 'rideType' || $prevParent === 'make') {
        $('input[name=subModelGroup]').val('-1');
        $('input[name=modelGroup]').val('-1');
        $('input[name=engineTypes]').val('-1');
      }
      if ($nextChild) {
        let $hasDefaultButton = $nextChildDropdown.find('button[data-val=-1]').length,
          $defaultButton = '<button data-val="-1" data-selected="true"></button>';
        if ($hasDefaultButton != 1) {
          $nextChildDropdown.prepend($defaultButton);
        }
        self.resetOptions($nextChild);
      }
      let $hasDefaultButton = $childParent.find('button[data-val=-1]').length,
        $defaultButton = '<button data-val="-1" data-selected="true"></button>';
      if ($hasDefaultButton != 1) {
        $nextDropdown.prepend($defaultButton);
      }
      self.resetValue($childParent);
      $childParent.find('button:gt(0)').remove();
      $childParent.find('button').attr('data-selected', 'true');
      $childParent.data('name');
    },
    selectOption: function (currentButton, nextSelect) {
      const self = this;
      const $button = $(currentButton),
        $currentSelect = $button.parents('.rf__select').data('name'),
        $value = $button.data('val');
      let $nextOption = self.$form.find(`.rf__select[data-name="${nextSelect}"]`);
      $button.parents('.rf__collapse-list').find('button').attr('data-selected', 'false');
      $button.attr('data-selected', 'true');
      self.$form.find(`input[name="${$currentSelect}"]`).val($value);
      if ($nextOption.length > 0) {
        self.loadOptions($nextOption);
      }

      // if all the options are loaded, submit the form
      if (self.submit(self.$form)) {
        return;
      }
    },
    autoOptionSelect: function ($select) {
      const self = this;
      let $autoButton,
        listName = $select.attr('data-name'),
        $button = $select.find('button:gt(0)'),
        $childParent = $select.parents('.rf__collapse-list'),
        $nextChild = $childParent.data('ride-selector-child'),
        autoSelect = $childParent.data('autoselect'),
        autoValue = self.$form.find(`input[name=${listName}]`).val(),
        autoType = self.$form.find('input[name=powersports]').attr('title');
      if (autoSelect) {
        if (autoValue === '-1') {
          $autoButton = $select.children().filter(function () {
            return $(this).text() === autoType;
          });
        } else {
          $autoButton = $select.children(`[data-val=${autoValue}]`);
        }
        self.autoSelect($select, $autoButton);
        self.selectOption($autoButton, $nextChild);
      } else {
        self.collapse = $select;
        self.selectOption($button, $nextChild);
        self.autoSelect($select, null);
      }
    },
    getOptionsFromServer: function (params, $select) {
      const self = this;
      if (!params) {
        console.warn('No parameters to send to the server. Will not send a failing request.');
        return;
      }
      let spinner = new Spinner(),
        $form = $(self.options.rideSelectorId);
      $.ajax({
        type: 'GET',
        url: self.options.uri,
        data: params,
        dataType: 'json',
        async: true,
        contentType: 'application/json; charset=utf-8',
        timeout: 7000,
        beforeSend: function () {
          spinner.spin($form.get()[0]);
        },
        complete: function () {
          spinner.stop();
        }
      }).retry({
        times: 3,
        timeout: 1000
      }).done(function (data) {
        self.populateOptions(data, $select);
      }).fail(function (xhr, textStatus, errorThrown) {
        DK.toggleAlert($('#ajax-error'), 'Error fetching ride information. Please refresh the page and try again.', 'alert');
      });
    },
    loadFirstSelect: function ($form) {
      const self = this;
      const $select = self.$form.find('.rf__select');
      $form.data('ride-selector-loaded', true);
      self.loadOptions($select.filter(':first'));
    },
    loadOptions: function ($select) {
      const self = this;
      let listName = $select.attr('data-name'),
        params = self.$form.serializeArray();
      params.push({
        name: 'type',
        value: listName
      });
      self.getOptionsFromServer(params, $select);
    },
    populateOptions: function (data, $select) {
      const self = this;
      $.each(data, function (index, rideSelectorOption) {
        // Create an <option> element for each of the values
        // returned from the server and add them to the select list.
        // Readd the default option

        const $option = $('<button/>');
        $option.attr('data-val', rideSelectorOption.value).attr('role', 'option').html(rideSelectorOption.name).appendTo($select);
      });

      // Add an empty unselectable option when there are no
      // available options.
      let $selectedButton = $select.find('button[data-selected=true]'),
        $collapse = $select.parents('.rf__collapse-list');
      let $firstSelect = $select.parents('[data-collapsed]'),
        $parent = $collapse.data('rideSelectorParent') ? self.$form.find(`[data-id=rs-${$collapse.data('rideSelectorParent')}]`) : undefined,
        $nextSelect = self.$form.find('[data-name="make"]'),
        selectName = $select.data('name');
      let autoSelect = $collapse.data('autoselect') === true,
        optionalRideType = $select.hasClass('opt-parent'),
        parentHadAutoSelect = $parent !== undefined ? $parent.data('autoselect') === true : undefined;

      //Don't autoselect model to prevent auto redirect
      if (data.length === 1 && selectName !== 'model' || autoSelect && selectName !== 'model') {
        //If there's only one option or if it's autoselect, then... autoselect it
        self.autoOptionSelect($select);
      } else if ($parent !== undefined && autoSelect || $parent !== undefined && parentHadAutoSelect) {
        //If the current selector has a parent and it's autoselect or the parent was autoselecetd
        //then remove the disabled class from the select
        $collapse.removeClass('-disabled');
      } else if ($firstSelect.length < 1) {
        //If it's not the first select?
        if (optionalRideType) {
          //If no specific ride type on page load, make it optional.
          $collapse = $nextSelect.parents('.rf__collapse-list');
          $collapse.removeClass('-disabled');
          $select.removeClass('opt-parent');
        } else {
          //On certain landing pages, autodrop the next list with no selected value.
          self.show($collapse);
          $collapse.removeClass('-disabled');
          self.focus($selectedButton);
        }
      } else if (self.options.startCollapsed === true) {
        self.loadOptions($nextSelect);
      } else {
        //If none of the above are true, load the Make dropdown.
        // Should only happen on page load? but not sure how
        self.loadOptions($nextSelect);
      }

      //Show Year on model group pages if ride type & make are selected
      if ($collapse.hasClass('rf__collapse-list--autoyr') && data.length !== 1) {
        // Only show on desktop
        $(window).breakpoints('greaterThan', 'lg', function () {
          self.show($collapse);
        });
      }
    },
    submit: function ($form) {
      // Submit the form if all options are selected - ride type being optional
      let numberOfDefaultSelects = $form.find('input[type="hidden"]').filter(':not([name="rideType"])').filter(function (i) {
        return $(this).val() == -1;
      }).length;
      if (numberOfDefaultSelects === 0) {
        let spinner = new Spinner();
        DK.displayBlocker($form);
        spinner.spin($form.get()[0]);
        $form.trigger('rideFormSubmission');
        $form.trigger('submit');
        return true;
      }
      return false;
    },
    generateId: function (element) {
      const self = this;
      // Sets an automatically generated id in case there is none.
      // This is to differentiate between multiple ride selectors
      // on the page.
      if (!element.id) {
        element.id = `ride-selector-${Math.random().toString(36).substr(2, 6)}`;
      }
      self.options.rideSelectorId = `#${element.id}`;
      self.$form = $(element);
      self.loadFirstSelect(self.$form);
      return element;
    },
    removeEvents: function () {
      $(document).off('click', '.rf__collapse-list:not(.-disabled)');
      $(document).off('keydown', '.rf__collapse-list:not(.-disabled)');
      $(document).off('click', '.rf__select button:not([data-val=-1])');
      $(document).off('click', '.rf__select button[data-val=-1]');
    },
    capitalize: function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1);
    }
  };
})(jQuery, window);
$(window).on('load', function () {
  $('.rf:not(.rf--modal) [data-morty]').morty({
    modal: false,
    startCollapsed: true
  });
});
$(function () {
  let modalFinder = $('.modal--rf:not(.rf--page)');
  modalFinder.each(function (i, e) {
    e.addEventListener('loaded.dk.modal', event => {
      $(this).find('[data-morty]').morty({
        modal: true
      });
    });
  });
});

// If you click active ride title, go to link
// Otherwise close or open specs
// It's not ideal
function rideClick(e) {
  window.location = $(e).attr('href');
}