(function ($) {
  $.fn.sizeselector = function () {
    return this.each(function (index, element) {
      let settings = {
        uri: DK.url('finder/tire/size-finder.jsonp'),
        landingFunction: null,
        sizeSelectorId: null,
        visibilityPollingInterval: 100
      };

      /**
       * Removes all of the future select list options from being prematurely selected
       * @param select {jqObject} of the active select list, remove, sibling options
       * */
      function clearSelectsAfter(select) {
        if (!select) {
          return;
        }
        // Remove all but the first element from the list
        // First element should be "Select <tireType>"
        let siblings = select.parents('.size-select-field').nextAll();
        siblings.find('select').each(function (i, selectElement) {
          let $select = $(selectElement);
          let preservedDefault = $select.children().first();
          $select.children().remove();
          $select.append(preservedDefault);
        });
      }

      /**
       * Load data sizes to select list options that are currently available
       * @param data {Array} list of size data
       * @param selector {jqObject} jquery selector element to append size options to
       * */
      function loadSizeList(data, selector, $parent) {
        $.each(data, function (index) {
          $('<option />').attr('value', data[index]).html(data[index]).appendTo(selector);
          //if "100" is an option for the aspect ratio use it to populate the rim diameter list
          //The goal is to alleviate confusion with tire sizes not showing an aspect ratio on the side wall (3.00-19, 2.50-10, etc.)
          if (data[index] === '100' && selector.attr('id') === 'aspect') {
            $parent.find('[name="aspect"] option:first').val(data[index]);
            populateDropdown($parent, $parent.find('[name="rimsize"]'), {
              tireType: $parent.find('[name="tireType"]').val(),
              width: $parent.find('[name="width"]').val(),
              aspect: data[index],
              brandId: $parent.find('[name="brandId"]').val()
            });
          }
        });
      }

      /**
       *
       * Get sizing json for selector
       * @param selector {jqObject} is the select list to append the incoming JSON data to
       * @param jsondata {json} json sizing data object
       * */
      function populateDropdown($parent, selector, jsondata) {
        let spinner = new Spinner();
        $.ajax({
          type: 'GET',
          async: true,
          url: settings.uri,
          contentType: 'application/json; charset=utf-8',
          data: jsondata,
          beforeSend: function () {
            DK.displayBlocker($parent);
            spinner.spin($parent.get()[0]);
          },
          complete: function () {
            DK.hideBlocker($parent);
            spinner.stop();
          }
        }).retry({
          times: 3,
          timeout: 1000
        }).done(function (data) {
          loadSizeList(data, selector, $parent);
        }).fail(function (xhr, textStatus, errorThrown) {
          DK.toggleAlert($parent.find('.alert-box'), 'Error fetching tire information. Please refresh the page and try again.', 'alert');
        });
      }

      /**
       *
       * set up Event Handlers for tire size select lists
       *
       * */
      function setupSizeSelector($form) {
        let $width = $form.find('[name="width"]'),
          $aspect = $form.find('[name="aspect"]'),
          $diameter = $form.find('[name="diameter"]'),
          $rimsize = $form.find('[name="rimsize"]'),
          $tireType = $form.find('[name="tireType"]').val(),
          $brandId = $form.find('[name="brandId"]').val(),
          spinner = new Spinner();
        let $selector = $form.find('select:first');
        $selector.find('option:gt(0)').remove();
        $selector.find('option')[0].selected = true;

        /**
         *
         * Attach Event to 'Tire Width' drop down
         * on change, get the next diameter value
         *
         * */
        $width.off();
        $width.on('change', function () {
          let widthVal = $width.val();
          let diameterVal = $diameter.val();
          if (widthVal !== '-0') {
            if ($tireType === '4wheel') {
              populateDropdown($form, $rimsize, {
                tireType: $tireType,
                width: widthVal,
                diameter: diameterVal,
                brandId: $brandId
              });
            } else if ($tireType === '2wheel') {
              populateDropdown($form, $aspect, {
                tireType: $tireType,
                width: widthVal,
                brandId: $brandId
              });
            }
          }
          clearSelectsAfter($width);
        });

        /**
         *
         * Attach Event to 'Tire Diameter' drop down
         * on change, get the next diameter value
         * Only for ATV & UTV Tire Finder
         *
         * */
        $diameter.off();
        $diameter.on('change', function () {
          let diameterVal = $diameter.val();
          if (diameterVal !== '-0') {
            populateDropdown($form, $width, {
              tireType: $tireType,
              diameter: diameterVal,
              brandId: $brandId
            });
          }
          clearSelectsAfter($diameter);
        });

        /**
         *
         * Attach Event to aspect ratio drop down
         * on change, get the next diameter value
         * Only for Motorcycle Tire Finder
         *
         * */
        $aspect.off();
        $aspect.on('change', function () {
          let widthVal = $width.val();
          let aspectVal = $aspect.val();
          if (aspectVal !== '-0') {
            populateDropdown($form, $rimsize, {
              tireType: $tireType,
              width: widthVal,
              aspect: aspectVal,
              brandId: $brandId
            });
          }
          clearSelectsAfter($aspect);
        });

        /**
         *
         * Attach Event to 'Rim Diameter' drop down
         *
         * */
        $rimsize.on('change', function () {
          let widthVal = $width.val();
          let aspectVal = $aspect.val();
          let diameterVal = $diameter.val();
          let rimsize = $rimsize.val();
          if (rimsize !== '-0') {
            if (settings.landingFunction) {
              if ($tireType === '4wheel') {
                settings.landingFunction(diameterVal, widthVal, rimsize);
              } else if ($tireType === '2wheel') {
                settings.landingFunction(widthVal, aspectVal, rimsize);
              }
            } else {
              DK.displayBlocker($form);
              spinner.spin($form.get()[0]);
              $form.trigger('submit');
            }
          }
        });
        $selector.val('-0');
        clearSelectsAfter($selector);
        if ($tireType === '4wheel') {
          populateDropdown($form, $diameter, {
            tireType: $tireType,
            brandId: $brandId
          });
          //hack to ensure "Tire Width" is displayed
          $form.find('[name="width"]').val('-0');
        } else if ($tireType === '2wheel') {
          populateDropdown($form, $width, {
            tireType: $tireType,
            brandId: $brandId
          });

          //hack to ensure "Aspect Ratio" is displayed
          $form.find('[name="aspect"]').val('-0');
        }
        $form.data('tireSizeLoaded', true);
      }
      if (!element.id) {
        element.id = `tire-size-selector-${Math.random().toString(36).substr(2, 6)}`;
      }
      settings.sizeSelectorId = `#${element.id}`;
      let $form = $(settings.sizeSelectorId);
      if ($form.data('tireSizeLoaded') !== true) {
        // only load the first select for ride selector widgets that are visible.
        // Hidden ride selectors will have their first select populated when
        // they become visible.  Chrome, Firefox, Edge, and Opera can use
        // the Intersection Observer API, other browsers fall back to
        // $.is(':visible') on an interval.
        // see:
        //  https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //  https://jcubic.wordpress.com/2017/05/12/execute-callback-in-javascript-when-a-div-change-visibility-with-html5/
        if ($form.is(':visible')) {
          // console.log("Initializing visible tire size selector");
          setupSizeSelector($form);
        } else {
          if (window.IntersectionObserver) {
            let observer = new IntersectionObserver(function (entries) {
              if (entries[0].intersectionRatio && $form.data('tireSizeLoaded') !== true) {
                setupSizeSelector($form);
              }
            });
            observer.observe(element);
          } else {
            let intervalId = window.setInterval(function () {
              if ($form.data('tireSizeLoaded') !== true && $form.is(':visible')) {
                setupSizeSelector($form);
                window.clearInterval(intervalId);
              }
            }, settings.visibilityPollingInterval);
          }
        }
      }
    });
  };
})(jQuery);
$(function () {
  $('[data-tire-size-selector]').sizeselector();
});