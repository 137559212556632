//Handles bootstrap toasts
(function ($) {
  //Defaults
  $.toastDefaults = {
    position: '',
    dismissible: true,
    stackable: true,
    pauseDelayOnHover: true,
    style: {
      toast: '',
      info: '',
      success: '',
      warning: '',
      error: ''
    }
  };

  // Remove toast from container when it closes
  $('body').on('hidden.bs.toast', '.toast', () => {
    $('.toast').remove();
  });

  // Count necessary for delay to work on stacked toasts
  let toastCount = 1;
  let toastContainerTemplate = `<div aria-live="polite" aria-atomic="true" class="position-relative">
			<div id="toast-container" class="toast-container position-fixed p-3">
			</div>
		</div>`;
  function render(opts) {
    // Create toast container if it doesn't exist
    if (!$('#toast-container').length) {
      $('body').prepend(toastContainerTemplate);
    }
    let toastContainer = $('#toast-container');
    let html = '';
    let classes = {
      header: {
        fg: '',
        bg: '',
        close: ''
      },
      subtitle: 'text-muted',
      dismiss: 'text-white',
      position: 'top-0 end-0'
    };
    let id = opts.id || `toast-${toastCount}`;
    let type = opts.type;
    let position = opts.position;
    let title = opts.title;
    let subtitle = opts.subtitle;
    let content = opts.content;
    let icon = opts.icon;
    let delayOrAutohide = opts.delay ? `data-delay="${opts.delay}"` : `data-bs-autohide="false"`;
    let hideAfter = ``;
    let dismissible = $.toastDefaults.dismissible;
    let globalToastStyles = $.toastDefaults.style.toast;
    let paused = false;
    if (typeof opts.dismissible !== 'undefined') {
      dismissible = opts.dismissible;
    }

    //Position currently affects the entire toast container.
    switch (position) {
      case 'top-left':
        classes.position = $.toastDefaults.position || 'top-0 start-0';
        break;
      case 'top-right':
        classes.position = $.toastDefaults.position || 'top-0 end-0';
        break;
      case 'bottom-left':
        classes.position = $.toastDefaults.position || 'bottom-0 start-0';
        break;
      case 'bottom-right':
        classes.position = $.toastDefaults.position || 'bottom-0 end-0';
        break;
    }
    // add the position to the toast container
    if ($('#toast-container').length) {
      $('#toast-container').addClass(classes.position);
    }

    // Set specific class names
    switch (type) {
      case 'primary':
        classes.header.bg = $.toastDefaults.style.info || 'bg-primary';
        classes.header.fg = $.toastDefaults.style.info || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
      case 'secondary':
        classes.header.bg = $.toastDefaults.style.info || 'bg-secondary';
        classes.header.fg = $.toastDefaults.style.info || 'text-dark';
        break;
      case 'success':
        classes.header.bg = $.toastDefaults.style.success || 'bg-success';
        classes.header.fg = $.toastDefaults.style.info || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
      case 'error':
      case 'danger':
        classes.header.bg = $.toastDefaults.style.error || 'bg-danger';
        classes.header.fg = $.toastDefaults.style.error || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
      case 'warning':
        classes.header.bg = $.toastDefaults.style.warning || 'bg-warning';
        classes.header.fg = $.toastDefaults.style.warning || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
      case 'info':
        classes.header.bg = $.toastDefaults.style.info || 'bg-info';
        classes.header.fg = $.toastDefaults.style.info || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
      case 'light':
        classes.header.bg = $.toastDefaults.style.info || 'bg-light';
        classes.header.fg = $.toastDefaults.style.info || 'text-dark';
        break;
      case 'dark':
        classes.header.bg = $.toastDefaults.style.info || 'bg-dark';
        classes.header.fg = $.toastDefaults.style.info || 'text-white';
        classes.header.close = $.toastDefaults.style.info || 'btn-close-white';
        break;
    }

    //Set delay
    if ($.toastDefaults.pauseDelayOnHover && opts.delay) {
      delayOrAutohide = `data-bs-autohide="false"`;
      hideAfter = `data-bs-delay="${Math.floor(Date.now() / 1000) + opts.delay / 1000}"`;
    }

    // If there is a `title`
    if (title) {
      html = `<div id="${id}" class="toast ${globalToastStyles}" role="alert"
				aria-live="polite" aria-atomic="true" ${delayOrAutohide} ${hideAfter}>`;
      _dismissable = '';
      _subtitle = '';
      _icon = '';
      if (dismissible) {
        _dismissable = `<button type="button" class="${classes.header.close}
					btn-close" data-bs-dismiss="toast" aria-label="Close"></button>`;
      }
      if (subtitle) {
        _subtitle = `<small>${subtitle}</small>`;
      }
      if (icon) {
        _icon = `<i class="${icon} me-2"></i>`;
      }
      html += `<div class="toast-header ${classes.header.bg}
				${classes.header.fg}">
				${_icon}
				<strong class="me-auto">${title}</strong>
				${_subtitle}
				${_dismissable}
			</div>`;
      if (content) {
        html += `<div class="toast-body">${content}</div>`;
      }
      html += `</div>`;
      // If there is no title, we have to put the color into the actual body
      // Used for snack
    } else {
      html = `<div id="${id}" class="toast ${globalToastStyles} ${classes.header.bg} ${classes.header.fg}" role="alert" aria-live="assertive" aria-atomic="true" ${delayOrAutohide} ${hideAfter}>`;
      if (content) {
        // If we don't have the title, we need to add the close button
        if (dismissible) {
          html += `<div class="d-flex"><div class="toast-body">${content}</div>
						<button type="button" class="btn-close ${classes.header.close}
						me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
						</div>`;
        } else {
          html += `<div class="toast-body">${content}</div>`;
        }
      }
      html += `</div>`;
    }

    // Stackable if there's more than 1
    if (!$.toastDefaults.stackable) {
      toastContainer.find('.toast').each(function () {
        $(this).remove();
      });
      toastContainer.append(html);
      toastContainer.find('.toast:last').toast('show');
    } else {
      toastContainer.append(html);
      toastContainer.find('.toast:last').toast('show');
    }

    // Deal with the delay
    if ($.toastDefaults.pauseDelayOnHover) {
      setTimeout(function () {
        if (!paused) {
          $(`#${id}`).toast('hide');
        }
      }, opts.delay);

      //Stop the delay on hover
      $('body').on('mouseover', `#${id}`, function () {
        paused = true;
      });

      //When the mouse leaves, continue the delay if it's not over
      $(document).on('mouseleave', '#' + id, function () {
        const current = Math.floor(Date.now() / 1000),
          future = parseInt($(this).data('bsDelay'));
        paused = false;
        if (current >= future) {
          $(this).toast('hide');
        }
      });
    }
    //Increment the counter
    toastCount++;
  }

  /**
   * Show a snackbar -- one line toast
   * @param type
   * @param title
   * @param delay
   */
  $.snack = function (type, content, delay) {
    return render({
      type,
      content,
      delay
    });
  };

  /**
   * Show a toast
   * @param opts
   */
  $.toast = function (opts) {
    return render(opts);
  };
})(jQuery);