$(document).on('click', '.spec-tab', function (e) {
  e.preventDefault();
  let isActive = $(e.currentTarget).hasClass('tab-active');
  let content = $(e.currentTarget).data('content');
  if (isActive) {
    $(e.currentTarget).removeClass('tab-active');
    $(content).hide();
  } else {
    $('.spec-tab').removeClass('tab-active');
    $('#specs-wrapper , #change-wrapper').hide();
    $(e.currentTarget).addClass('tab-active');
    $(content).show();
  }
});
$(document).on('change', '#ride-spec-status .tgl > input', function (e) {
  e.preventDefault();
  let $checkbox = $(e.target),
    $toggle = $checkbox.parents('.tgl');
  new Spinner().spin($toggle.get()[0]);
  DK.displayBlocker('body');
  if ($checkbox.is(':checked')) {
    if (typeof ga != 'undefined') {
      ga('send', 'event', 'Ride', 'toggle-ride-on', $checkbox.data('rideName'));
    }
    $checkbox.prop('disabled', 'disabled');
    document.location = `${$checkbox.data('rideOn')}?rideId=${$checkbox.data('rideId')}`;
  } else {
    if (typeof ga != 'undefined') {
      ga('send', 'event', 'Ride', 'toggle-ride-off', $checkbox.data('rideName'));
    }
    $checkbox.prop('disabled', 'disabled');
    document.location = `${$checkbox.data('rideOff')}?rideId=${$checkbox.data('rideId')}`;
  }
});